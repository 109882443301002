import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const checkboxFor = field => ({ handleCheckboxChange, handleFocus, handleBlur, inputs, label, ...props }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={inputs.fields[field].value}
        onChange={handleCheckboxChange(field)}
        value={inputs.fields[field].value}
        {...props}
      />
    }
    label={label}
  />
);

export default checkboxFor;
