import React, { Fragment, useCallback, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import useForm, { DEFAULT_FIELD, DEFAULT_BOOLEAN_FIELD } from '../hooks/useForm';
import FullName from '../components/Form/inputs/FullName';
import Email from '../components/Form/inputs/Email';
import Password from '../components/Form/inputs/Password';
import checkboxFor from '../components/Form/inputs/Checkbox';
import ButtonWithLoading from '../components/Form/inputs/ButtonWithLoading';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';

import Carousel from '../components/Carousel';
import Slide from '../components/Carousel/Slide';

const noop = () => { return void 0 }
const Checkbox = checkboxFor('isAdmin');

const InputNumber = ({ name, label, min = 0, max, classes, ...events }) => {
  return (
    <React.Fragment>
      <div className={classes.inputGroup} >
        <label htmlFor={name} className={classes.inputLabel} >{label}</label>
        <input
          type="number"
          id={name}
          name={name}
          min={min}
          max={max}
          className={classes.input}
          {...events}
        />
      </div>
    </React.Fragment>
  )
}

const InputBoolean = ({ name, label, classes, ...events }) => {
  return (
    <React.Fragment>
      <div className={classes.inputGroup} >
        <label htmlFor={name} className={classes.inputLabel} >{label}</label>
        <input type="checkbox" id={name} name={name} {...events} className={classes.input}/>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  input: {
    padding: 4,
  },
  inputLabel: {
    padding: 4,
  },
  inputGroup: {
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  isAdmin: {
    marginTop: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
  link: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  engage: {
    padding: theme.spacing(1),
  },
}));

const validators = {}

const CarouselPage = (props) => {
  const classes = useStyles();
  const change = useCallback((index) => {
    console.log('change', index)
  }, [])

  const {
    inputs,
    handleInputChange,
    handleCheckboxChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success 
  } = useForm(
    props,
    noop,
    { 
      space: { ...DEFAULT_FIELD, value: 240 }, 
      width: { ...DEFAULT_FIELD, value: 360 }, 
      height: { ...DEFAULT_FIELD, value: 270 },
      border: { ...DEFAULT_FIELD, value: 1 },
      perspective: { ...DEFAULT_FIELD, value: 35 },
      scaling: { ...DEFAULT_FIELD, value: 300 },
      visible: { ...DEFAULT_FIELD, value: 5 },
      loop: { ...DEFAULT_FIELD, value: true },
      disable3d: { ...DEFAULT_FIELD, value: false },
      animationSpeed: { ...DEFAULT_FIELD, value: 500 },
      direction: { ...DEFAULT_FIELD, value: true },
      autoplayTimeout: { ...DEFAULT_FIELD, value: 2000 },
    }
  );


  return (
    <Fragment>
      <Carousel 
        controlsVisible
        perspective={inputs.fields["perspective"].value}
        display={inputs.fields["visible"].value}
        autoplay
        beforeSlideChange={change}
        space={inputs.fields["space"].value}
        height={inputs.fields["height"].value}
        width={inputs.fields["width"].value}
        border={inputs.fields["border"].value}
        disable3d={inputs.fields["disable3d"].value}
        scaling={inputs.fields["scaling"].value}
        animationSpeed={inputs.fields["animationSpeed"].value}
        autoplayTimeout={inputs.fields["autoplayTimeout"].value}
      >
        <Slide key={1}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+1`} />
        </Slide>
        <Slide key={2}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+2`} />
        </Slide>
        <Slide key={3}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+3`} />
        </Slide>
        <Slide key={4}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+4`} />
        </Slide>
        <Slide key={5}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+5`} />
        </Slide>
        <Slide key={6}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+6`} />
        </Slide>
        <Slide key={7}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+7`} />
        </Slide>
        <Slide key={8}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+8`} />
        </Slide>
        <Slide key={9}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+9`} />
        </Slide>
        <Slide key={10}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+10`} />
        </Slide>
        <Slide key={11}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+11`} />
        </Slide>
        <Slide key={12}>
          <img src={`https://via.placeholder.com/${inputs.fields["width"].value}x${inputs.fields["height"].value}?text=Slide+12`} />
        </Slide>
      </Carousel>
      <React.Fragment>
        <form onSubmit={event => handleSubmit(event, inputs)} className={classes.container}>
          <InputNumber
            name="space"
            min="0"
            max="500"
            label="Slide space"
            classes={classes}
            onChange={handleInputChange("space")}
            onBlur={handleBlur("space")}
            onFocus={handleFocus("space")}
            value={inputs.fields["space"].value}
          />
          <InputNumber
            name="width"
            min="100"
            max="500"
            label="Slide width"
            classes={classes}
            onChange={handleInputChange("width")}
            onBlur={handleBlur("width")}
            onFocus={handleFocus("width")}
            value={inputs.fields["width"].value}
          />
          <InputNumber
            name="height"
            min="100"
            max="500"
            label="Slide height"
            classes={classes}
            onChange={handleInputChange("height")}
            onBlur={handleBlur("height")}
            onFocus={handleFocus("height")}
            value={inputs.fields["height"].value}
          />
          <InputNumber
            name="border"
            min="0"
            max="100"
            label="Border"
            classes={classes}
            onChange={handleInputChange("border")}
            onBlur={handleBlur("border")}
            onFocus={handleFocus("border")}
            value={inputs.fields["border"].value}
          />
          <InputNumber
            name="perspective"
            min="0"
            max="89"
            label="Slide perspective"
            classes={classes}
            onChange={handleInputChange("perspective")}
            onBlur={handleBlur("perspective")}
            onFocus={handleFocus("perspective")}
            value={inputs.fields["perspective"].value}
          />
          <InputNumber
            name="scaling"
            min="0"
            max="89"
            label="Slide scaling"
            classes={classes}
            onChange={handleInputChange("scaling")}
            onBlur={handleBlur("scaling")}
            onFocus={handleFocus("scaling")}
            value={inputs.fields["scaling"].value}
          />
          <InputNumber
            name="visible"
            min="1"
            max="50"
            label="Visible slides"
            classes={classes}
            onChange={handleInputChange("visible")}
            onBlur={handleBlur("visible")}
            onFocus={handleFocus("visible")}
            value={inputs.fields["visible"].value}
          />
          <InputBoolean
            name="loop"
            label="Infinite loop"
            classes={classes}
            onChange={handleCheckboxChange("loop")}
            checked={inputs.fields["loop"].value}
            value={inputs.fields["loop"].value}
          />
          <InputBoolean
            name="disable3d"
            label="Disable 3d"
            classes={classes}
            onChange={handleCheckboxChange("disable3d")}
            checked={inputs.fields["disable3d"].value}
            value={inputs.fields["disable3d"].value}
          />
          <InputNumber
            name="animationSpeed"
            min="0"
            max="10000"
            label="Animation speed"
            classes={classes}
            onChange={handleInputChange("animationSpeed")}
            onBlur={handleBlur("animationSpeed")}
            onFocus={handleFocus("animationSpeed")}
            value={inputs.fields["animationSpeed"].value}
          />
          <InputBoolean
            name="direction"
            label="Right direction"
            classes={classes}
            onChange={handleCheckboxChange("direction")}
            checked={inputs.fields["direction"].value}
            value={inputs.fields["direction"].value}
          />
          <InputNumber
            name="autoplayTimeout"
            min="0"
            max="10000"
            label="Delay"
            classes={classes}
            onChange={handleInputChange("autoplayTimeout")}
            onBlur={handleBlur("autoplayTimeout")}
            onFocus={handleFocus("autoplayTimeout")}
            value={inputs.fields["autoplayTimeout"].value}
          />
        </form>

      </React.Fragment>
    </Fragment>
  )
};

const CarouselP = () => (
  <Layout>
    <CarouselPage />
  </Layout>
);

export default CarouselP

